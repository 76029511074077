// HeroSection.js
import React, { useState, useEffect } from 'react';
import tech from '../../images/tech1.mp4'
import image1 from '../../images/Frame 19.png'
import image2 from '../../images/download.png'
import image3 from '../../images/laptop.png'
import image4 from '../../images/laptop1.png'
import image5 from '../../images/laptop2.png'

const HeroSection = (props) => {
  const { myRef } = props
  const [images] = useState([
    image1,
    image2,
    image3,
    image4,
    image5
    // Add more image URLs here
  ]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);


  useEffect(() => {
    const interval = setInterval(() => {
      // Increment the image index, and loop back to the first image when it reaches the end
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000); // Change image every 3 seconds (adjust as needed)

    // Clean up the interval when the component unmounts
    return () => clearInterval(interval);
  }, [images]);


  const currentImage = images[currentImageIndex];
  return (
    <div ref={myRef}>
      <div className="hero-container">
        <video autoPlay muted loop className="video-background">
          <source src={tech} type="video/mp4" />
          {/* Add other video format sources for compatibility */}
        </video>
        <div className="hero-content">
          <div className="text-content">
            <h1><b className='text-danger'>IT and Software</b> consulting.
              Empowering your digital presence.
            </h1>
            <a href="mailto:info@numotiv.io">
              <button className='CustomBtn btn btn-lg btn-block btn-danger mt-2'>Get in touch</button>
            </a>
          </div>
          <div className="image-content">

            <img
              src={currentImage}
              alt="Slideshow"

            />

          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
