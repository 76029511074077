import React from 'react'
import feature1 from '../../images/feature_1.png'
import feature2 from '../../images/feature_2.png'
import feature3 from '../../images/feature_3.png'
import ServiceBox from './ServiceBox'

export default function Service(props) {
    const { myRef } = props

    const services = [
        {
            image: feature1,
            title: "Website and App Development",
            des: "Our mission is to provide businesses with the latest and most efficient websites and apps, empowering them to stay competitive in today’s fast paced digital landscape."
        },
        {
            image: feature3,
            title: "GDPR Consulting and DPO Services",
            des: "Our team of certified GDPR experts bring a wealth of knowledge and practical experience to assess your current data protection practices to identify gaps and areas that need improvement. We also provide DPO services to the organisations that require it. Along with a GDPR training to empower your staff with the knowledge they need."
        },
        {
            image: feature2,
            title: "Software and Technology Reselling",
            des: "We are passionate about technology and its potential to transform businesses. Our access to top notch software and IoT solutions, enable us to deliver value through innovation, reliability and exceptional customer service."
        },

    ]

    return (
        <div id="service" ref={myRef}>
            <h1 className='text-center p-4 heading'>
                <p className='text-center topHeading'>
                    <b className='text-danger'>Services</b>
                </p>
                <b>What Solutions We Provide</b>
            </h1>
            <div className="a-container mt-4">
                {services.map((service, index) => (
                    <div key={index}>
                        <ServiceBox
                            img={service.image}
                            title={service.title}
                            des={service.des}
                        />
                    </div>
                ))}
            </div>

        </div>
    )
}
