
import React, { useRef } from "react"
import HeroSection from "./Components/HeroSection/HeroSection";
import Navbar from "./Components/TopNav/Navbar";
import Service from "./Components/Service/Service";
import Technologies from "./Components/Technologies/Technologies";
import AboutUs from "./Components/AboutUs/AboutUs";
import './App.css'
import Footer from "./Components/Footer/Footer";

function App() {
  const myRefForService = useRef(null);
  const myRefForTech = useRef(null);
  const myRefForHero = useRef(null);
  const myRefForAbout = useRef(null);
  const myRefForContact = useRef(null);


  const scrollToRefHero = (e) => {
    e.preventDefault()
    myRefForHero.current.scrollIntoView({ behavior: 'smooth' });
  }

  const scrollToRefService = (e) => {
    e.preventDefault()
    myRefForService.current.scrollIntoView({ behavior: 'smooth' });
  };

  const scrollToRefTech = (e) => {
    e.preventDefault()
    myRefForTech.current.scrollIntoView({ behavior: 'smooth' });
  };

  const scrollToRefAbout = (e) => {
    e.preventDefault()
    myRefForAbout.current.scrollIntoView({ behavior: 'smooth' });
  };

  const scrollToRefContact = (e) => {
    e.preventDefault()
    myRefForContact.current.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <React.Fragment>
      <Navbar
        scrollToRefService={scrollToRefService}
        scrollToRefTech={scrollToRefTech}
        scrollToRefHero={scrollToRefHero}
        scrollToRefAbout={scrollToRefAbout}
        scrollToRefContact={scrollToRefContact}
      />
      <HeroSection myRef={myRefForHero} scrollToRefContact={scrollToRefContact} />
      <Service myRef={myRefForService} />
      <Technologies myRef={myRefForTech} />
      <AboutUs myRef={myRefForAbout} />
      <Footer
        myRef={myRefForContact}
        scrollToRefService={scrollToRefService}
        scrollToRefTech={scrollToRefTech}
        scrollToRefHero={scrollToRefHero}
        scrollToRefAbout={scrollToRefAbout}
      />
      

    </React.Fragment>
  );
}

export default App;
