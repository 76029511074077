import React, { useState } from 'react'
import logo from '../../images/numotivLogo.png'

export default function Navbar(props) {
    const { scrollToRefService, scrollToRefTech, scrollToRefHero, scrollToRefAbout, scrollToRefContact } = props
    const [nav, setnav] = useState(false);

    const changeBackground = () => {
        if (window.scrollY >= 50) {
            setnav(true)
        } else {
            setnav(false)
        }
    }

    window.addEventListener('scroll', changeBackground)
    return (
        <nav className={nav ? 'nav active' : 'nav'}>
            <a href='/nothing' className='logo' onClick={scrollToRefHero} >
                <img src={logo} alt='logo' />
            </a>
            <input type='checkbox' className='menu-btn' id="menu-btn" />
            <label className='menu-icon' for="menu-btn">
                <span className='nav-icon'></span>
            </label>
            <ul className='menu'>
                <li><a href='/nothing' onClick={scrollToRefService}>Services</a></li>
                <li><a href='/nothing' onClick={scrollToRefTech}>Technologies</a></li>
                <li><a href='/nothing' onClick={scrollToRefAbout}>About us</a></li>
                <li><a href='/nothing' onClick={scrollToRefContact}>Contact us</a></li>
            </ul>
        </nav>
    )
}
