import React, { useState, useEffect } from 'react'
import angular from '../../images/pngwing.com (8).png'
import react from '../../images/pngwing.com.png'
import php from '../../images/pngwing.com (2).png'
import node from '../../images/pngwing.com (3).png'
import javascript from '../../images/pngwing.com (4).png'
import html from '../../images/pngwing.com (5).png'
import css from '../../images/pngwing.com (6).png'
import cSharp from '../../images/pngwing.com (7).png'
import python from '../../images/pngwing.com (9).png'
import Java from '../../images/pngwing.com (10).png'
import iot from '../../images/pngwing.com (12).png'
import andriod from '../../images/pngwing.com (14).png'

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';


export default function Technologies(props) {
    const { myRef } = props
    const [slidesToShow, setSlidesToShow] = useState(3);
    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth >= 992) {
                setSlidesToShow(3);
            } else if (window.innerWidth >= 768) {
                setSlidesToShow(1);
            } else {
                setSlidesToShow(1);
            }
        };

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: slidesToShow,
        slidesToScroll: 1,
        autoplay: true, // Enable autoplay
        autoplaySpeed: 1000, // Set the time between each slide change in milliseconds (3 seconds in this example)
    };

    const services = [
        {
            image: html,
            title: "HTML5",
        },
        {
            image: css,
            title: "CSS3",
        },
        {
            image: javascript,
            title: "Javascript",
        },
        {
            image: react,
            title: "React JS",
        },
        {
            image: angular,
            title: "Angular",
        },
        {
            image: node,
            title: "Node Js",
        },
        {
            image: php,
            title: "PHP",
        },
        {
            image: cSharp,
            title: ".NET Core / C#",
        },

        {
            image: python,
            title: "Python",
        },
        {
            image: Java,
            title: "Java",
        },
        {
            image: andriod,
            title: "Andriod/IOS",
        },
        {
            image: iot,
            title: "IOT Tech",
        },
    ]

    return (
        <div ref={myRef} id='tech'>
            <div className='container mb-5'>
                <h1 className='text-center p-4 heading'>
                    <p className='text-center topHeading'>
                        <b className='text-danger'>Technologies</b>
                    </p>
                    <b className='text-white'>What Technologies We Are Using?</b>
                </h1>
                <div className='mt-5'>
                    <Slider {...settings}>
                        {services.map((service, index) => (
                            <div key={index}>
                                <div className="card h-100 align-items-center border-0">
                                    <img src={service.image} className="card-img-top w50"
                                        alt="Hollywood Sign on The Hill" />
                                    <div className="card-body">
                                        <h4 className="card-title pageFont text-white">{service.title}</h4>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>
        </div>
    )
}
