import React from 'react'

export default function ServiceBox(props) {
    return (
        <div style={{ display: "flex", justifyContent: "center" }}>
            <div className='a-box'>
                <div className='a-b-img'>
                    <img src={props.img} alt="Service pictures"/>
                </div>
                <div className='s-b-text pageFont'>
                    <h3>{props.title}</h3>
                    <p>{props.des}</p>
                </div>
            </div>
        </div>
    )
}
