import React from 'react'
import About from '../../images/about.png'

export default function AboutUs(props) {
    const { myRef } = props
    return (
        <div id='aboutUs' ref={myRef}>
            <div className='container mb-5'>
                <div className="row g-0">
                    <div className="col-md-8 d-flex align-items-center">
                        <div className="card-body">
                            <h1 className='p-2 heading'>
                                <p className='topHeading'>
                                    <b className='text-danger'>About us</b>
                                </p>
                            </h1>
                            <p className="h4">
                                Numotiv is a trusted partner in IT-enabled services and software development. We are your gateway to cutting-edge technology solutions designed to propel your business into the digital age.
                                <br />
                                <br />
                                Our vision is simple yet powerful: to be the catalyst that transforms your digital dreams into reality. We believe that technology should empower, not overwhelm, and our mission is to bridge the gap between your ideas and their implementation, making innovation accessible and achievable.

                            </p>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <img src={About} className="img-fluid rounded-start" alt="..." />
                    </div>

                </div>
            </div>
        </div>
    )
}
