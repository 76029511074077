import React from 'react'
import logo from '../../images/numotivLogo.png'

export default function Footer(props) {
    const { myRef } = props
    const { scrollToRefService, scrollToRefTech, scrollToRefHero, scrollToRefAbout } = props
    return (
        <div ref={myRef}>
            <footer className="w-100 py-4 flex-shrink-0" id="footer">
                <div className="container py-4 pageFont">
                    <div className="row gy-4 gx-5">
                        <div className="col-lg-6 col-md-6">
                            <a href='/nothing' className='logo' onClick={scrollToRefHero}>
                                <img src={logo} alt='logo' />
                            </a>
                            <p className="text-muted">Numotiv is a trusted partner in IT-enabled services and software development.</p>

                        </div>
                        <div className="col-lg-2 col-md-6">
                            <h5 className="text-white mb-3">Quick links</h5>
                            <ul className="list-unstyled text-muted">
                                <li><a href="/nothing" className='text-secondary' onClick={scrollToRefHero}>Home</a></li>
                                <li><a href="/nothing" className='text-secondary' onClick={scrollToRefService}>Services</a></li>
                                <li><a href="/nothing" className='text-secondary' onClick={scrollToRefTech}>Technologies</a></li>
                                <li><a href="/nothing" className='text-secondary' onClick={scrollToRefAbout}>About us</a></li>
                            </ul>
                        </div>
                        
                        <div className="col-lg-4 col-md-6">
                            <h5 className="text-white mb-3">Contact Us</h5>
                            <p className="text-muted">
                                Have questions or need more information? Reach out to us at
                                <b className='text-danger'> info@numotiv.io</b>
                                <br />
                                Let's discuss how we can help you leverage technology for your business's success.
                            </p>

                        </div>
                    </div>
                    <hr className='border border-white' />
                    <p className="text-muted mb-0 text-center">&copy; Copyrights. All rights reserved.
                        <a href="/numotiv.io"> numotiv.io</a>
                    </p>
                </div>
            </footer>
        </div>
    )
}
